<template>
    <div id="news" class="news">
        <div class="newsback" @click="backhome"><i class="el-icon-arrow-left"></i>返回首页</div>
        <div>

            <div class="menhu">
                <img src="../assets/az/Frame 21.png" class="img1">
            </div>
        </div>
        <div id="content">
            <div>
                <img src="../assets/az/Group 238503.png">
            </div>
            <div v-for="(item, index) in newslist" :key="index" class="news-box" @click="jumpnewsdetail(item)">
                <div class="news-left">
                    <div class="news-left-left">{{ item.newsHeadlines }}</div>
                    <div class="news-left-right">{{ item.createTime }}</div>
                </div>
                <div class="news-right">
                    <img :src="item.pressPhoto" class="news-img">
                </div>
            </div>
        </div>
        <div>
            <img src="../assets/az/Group 238492.png">
        </div>

    </div>
</template>
<script>
import { getnewList, getnewDetail } from '@/api/content/news'
export default {
    name: 'news',
    data() {
        return {
            newslist: []
        }
    }, methods: {
        backhome() {
            this.$router.push('/')
        },
        jumpnewsdetail(item) {
            console.log(item);
            if (item.newsType == 1) {
                window.open(item.newsLinks, '_blank')
            } else {
                const { href } = this.$router.resolve({
                    path: '/NewsDetail',
                    query: {
                        newsId: item.id
                    }
                });
                window.open(href, '_blank');
            }
        }
    }, created() {
        getnewList().then(res => {
            console.log(res)
            this.newslist = res.data

        }).catch(err => {
            console.log(err)
        })



    }
}
</script>
<style lang="less" scoped>
.news {
    text-align: center;
}

#content {
    background-color: #F5F9FA;
    padding: 20px 0;
}

.newsback {
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
}

.news-box {
    display: flex;
    padding: 20px;
    justify-content: space-between;
}

.news-left {

    margin-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.news-left-left {
    font-size: 14px;
    font-weight: bold;
    font-family: PingFang SC-Medium;
    text-align: left;
    line-height: 23px;

}

.news-left-right {
    text-align: left;
    font-size: 12px;
    color: #999999;

}

.news-img {
    width: 120px;
    height: 90px;
    border-radius: 8px;

}
</style>