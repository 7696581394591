<template>
    <div class="pagelg">
        <div class="headbg">

        </div>

        <div class="headbox">
            <div class="logo4box">
                <img class="logo4" src="../assets/logo4.png" alt="萝卜旅游logo">

            </div>
            <div class="tagsbox">
                <div class="tagsfont" v-for="item in tagsList" :key="item.id" @click="changeTag(item.id)"
                    :class="[activeIndex == item.id ? 'activetag' : '']">
                    {{ item.name }}
                </div>
            </div>

        </div>

        <div ref="mainone">
            <img src="../assets/xgbbanner.png" class="bannerimg">
        </div>
        <div ref="maintwo" class="textcenter">
            <img src="../assets/Group 238459@2x.png" class="chanpintitle" id="chanpin">
            <div class="chanpinmainbox">
                <div class="chanpinleftbox">
                    <div class="chanpinleftitle">
                        萝卜之旅，让心灵回归纯净之旅
                    </div>
                    <div class="chanpinleftext" @click="changeChanpin(1)" @mouseover="changeChanpin(1)">
                        热门酒店推荐
                    </div>
                    <div class="chanpinleftext" @click="changeChanpin(2)" @mouseover="changeChanpin(2)">
                        快速拼团
                    </div>
                    <div class="chanpinleftext" @click="changeChanpin(3)" @mouseover="changeChanpin(3)">
                        疯狂砍价
                    </div>

                </div>

                <div class="chanpinrightbox">
                    <img src="../assets/maintwo1.png" class="chanpinrightimg" alt="" v-if="activeChanpin == 1">
                    <img src="../assets/maintwo2.png" class="chanpinrightimg" alt="" v-if="activeChanpin == 2">
                    <img src="../assets/maintwo3.png" class="chanpinrightimg" alt="" v-if="activeChanpin == 3">
                </div>
            </div>
        </div>
        <div ref="mainthree" class="textcenter">
            <div class="hottitle">
                · 热门酒店 ·
            </div>
            <div class="hotmainbox">
                <div class="hotmainitem">
                    <div class="textcenter">
                        <img src="../assets/hotelone.png" alt="" class="hotmainimg">
                        <div class="hotelname">
                            重庆新世界酒店
                        </div>
                        <div class="hoteltext">
                            <span style="text-indent: 5px">
                                &nbsp &nbsp &nbsp &nbsp 重庆新世界酒店是璧山打造主城都市区“迎客厅”的标志性项目，是璧山推进中新(重庆)
                                合作的阶段性成果，由新加坡群策环球控股合资兴建。
                            </span>

                            <br />
                            <span style="text-indent: 2em">
                                &nbsp &nbsp &nbsp &nbsp
                                酒店地处璧山御湖岛中心，紧邻秀湖风景区，山环水绕、曲水叠岗，湖边驳岸和大面积浅水滩涂湿地较多，风景十分秀美。其占地面积102961㎡，设计注重建筑与自然、人文环境的和谐统一，传承了山水璧城悠久的文化风韵。酒店配置大堂吧、全日餐厅、中餐厅、大小型宴会厅及会议室、大型健身中心含室内泳池，专业外籍芳疗师带来的特色SPA体验以及两种风格十种类型的特色客房。

                            </span>

                        </div>
                    </div>

                </div>
                <div class="hotmainitem">
                    <div class="textcenter">
                        <img src="../assets/hoteltwo.png" alt="" class="hotmainimg">
                        <div class="hotelname">
                            天津中北假日酒店
                        </div>
                        <div class="hoteltext">
                            &nbsp &nbsp &nbsp &nbsp 天津中北假日酒店以其优越地理位置、通达的交通，坐拥西青区中北镇中央商务区，可直达城市旅游目的地；
                            <br />
                            &nbsp &nbsp &nbsp &nbsp
                            酒店坐拥天津市西青区中北镇CBD商圈，毗邻永旺购物中心及宜家天津中北商场。从酒店出发，步行约600米可达地铁2号线曹庄站。<br>
                            &nbsp &nbsp &nbsp &nbsp
                            酒店拥有二百余间时尚清新的客房， 融合渔樵江渚上的设计理念，呈现涟漪层层的设计格调，为您增添几分舒适惬意。
                            <br>
                            &nbsp &nbsp &nbsp &nbsp酒店设有2个餐厅和大堂吧，追求传统、健康、创意的饮食理念，为您呈现中西式美馔。
                            <br>
                            &nbsp &nbsp &nbsp &nbsp
                            酒店近1,000平米的会议及活动空间，布局灵活。350平方米的无柱式大宴会厅及7个布局灵活的会议场地，无论是商务会议、品质婚宴或社交活动，您的会议灵感均能付诸实现。
                            <br>
                            &nbsp &nbsp &nbsp &nbsp 酒店秉承 \"宾至如归, 笑由心生\" 的品牌服务理念，天津中北假日酒店是您商务预订、家庭出行、休闲旅游到访的理想之选
                        </div>
                    </div>

                </div>
                <div class="hotmainitem">
                    <div class="textcenter">
                        <img src="../assets/hotelthree.png" alt="" class="hotmainimg">
                        <div class="hotelname">
                            朗丽兹酒店
                        </div>
                        <div class="hoteltext">
                            酒店地址：酒店位于北京市三环内朝阳区弘善家园405号；<br>周边设施：东临北京著名潘家园古玩市场（华夏古玩城）仅需3分钟；西临北京肿瘤医院距酒店步行仅需5分钟可直达院区；<br>交通设施：酒店交通方便，周边有十里河地铁站可乘坐10号线/14号线，步行到地铁站只需10分钟左右交通便利，北京市第十八中学驾车约10分钟路程。可步行乘坐14号线倒1号线可到达国贸、西单等；<br>周边景区：可步行乘坐14号线倒7号线可到达北京环球度假区；雍和宫驾车约20分钟路程,国贸三里屯约驾车约15分钟的路程,地理位置优越。<br>产品特点：商场超市购物便利,周边配套齐全,满足您的商务旅行需求。朗丽兹酒店品牌作为宏昆酒店旗下中高端连锁品牌，以“创造非凡住宿体验”为使命，以精益求精的匠心，打造“有品位、很贴心”的产品与服务。房间装潢别具一格、简约而优雅，不同房型只为满足您的专属需求，优质的睡枕给您带来似家安眠，智能家居为居住增添一份灵活自如，自助免费洗衣房为您出行提供便利，朗丽兹酒店潘家园十里河地铁站店期待您的光临
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div ref="maintfour" class="textcenter">
            <!-- <div class="mytitle">
                · 关于我们 ·
            </div> -->
            <img src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238470@2x.png"
                class="ustitleimg" id="guanyuwomeng">
            <div class="uscontent">
                &nbsp &nbsp &nbsp &nbsp
                成都嘉瑜信息技术有限公司成立于2014年，注册资金1010万，拥有300余人的年轻精英团队，现已在武汉、西安、南京、重庆、郑州、杭州、长沙、深圳等城市设有分支机构。
                嘉瑜公司9年发展历程中于2016年在去哪儿网获得了销量第一，2017年在西南地区获得了核心地位称号，2018年获得了全国卓越进步奖，2019年排在了全国第八，2020年排在了全国第五，2021年获上半年KA供应商和年度大住宿核心供应商称号；OTA市场份额占西南地区约60%，年预订收入约4亿元，线下年订房量突破50万间。 
                嘉瑜致力于互联网+电子商务行业主营酒店OTA网络预定，自营平台打造中国互联网新模式化，酒店全方位服务网络订房新模式。洞悉消费需求精准预测市场，战略合作携程旅行、去哪儿、美团、飞猪、艺龙各大一线主流旅游网，有大平台数据优势，海量数据积累更懂用户消费习惯，基于用户当前实时下单及出行数据分析，掌握用户每分每秒的行为动态，了解目的地旅游实时动态，为目的地提供未来3个月的流量预测、热点挖掘。
                我们让用户有新创新、新体验在各大平台随心预定、精细化运营、数据分析、专业优化推广、排名优化方案、流量拓展、服务保障等铸造3大超级优势：超级流量、超级运营、超级传播，共同构筑起酒店不可复制的超强壁垒，在激烈的市场竞争中，占据未来制高点，实现线上线下双驱动，助力高收益，争做中高端住宿市场流量领跑企业。
            </div>

        </div>
        <div class="footbox">
               
            <div >
                成都嘉瑜信息技术有限公司
            </div>
            <div class="margin50">
                Chengdu Jiayu Information Technology Co., LTD
            </div>
            <div>
                蜀ICP备2020034211号-3 &nbsp|&nbsp 联系我们：189 8000 2456  &nbsp|&nbsp 地址： 四川省成都市成华区建设路10号钻石广场B座2905
            </div>

        </div>

    </div>
</template>
<script>

export default {
    name: 'Home',
    data() {
        return {
            tagsList: [
                { name: '首页', id: 1 },
                { name: '产品特色', id: 2 },
                { name: '热门酒店', id: 3 },
                { name: '关于我们', id: 4 },
            ],
            activeIndex: 1,
            activeChanpin: 1,
        }
    },
    methods: {
        changeTag(id) {
            console.log(this.activeIndex)
            console.log(id)
            this.activeIndex = id
            if (id == 1) {
                window.scrollTo({
                    top: this.$refs.mainone.offsetTop,
                    behavior: 'smooth'
                })
            }
            if (id == 2) {
                window.scrollTo({
                    top: this.$refs.maintwo.offsetTop,
                    behavior: 'smooth'
                })
            }
            if (id == 3) {
                window.scrollTo({
                    top: this.$refs.mainthree.offsetTop,
                    behavior: 'smooth'
                })
            }
            if (id == 4) {
                window.scrollTo({
                    top: this.$refs.maintfour.offsetTop,
                    behavior: 'smooth'
                })
            }

        },
        changeChanpin(id) {
            this.activeChanpin = id
        }

    }

}
</script>
<style lang="scss" scoped>
.pagelg {

    min-width: 1100px;
}

.headbg {
    object-fit: contain;

    z-index: 998;
    position: fixed;
    background-color: rgba(51, 51, 51, 0.2);
    /* 20% 透明度的黑色背景 */

    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    width: 100%;
    min-width: 1100px;
    height: 100px;

    top: 0;
    left: 0;
    opacity: 0.5;

}

.headbox {
    width: 100%;
    min-width: 1100px;
    height: 100px;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo4box {
    width: 50px;
    height: 50px;
}

.logo4 {
    position: fixed;
    width: 150px;
    height: auto;
    left: 10px;
    top: -30px;
    z-index: 999;
}

.tagsbox {
    width: 40%;
    margin-right: 10%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.tagsfont {
    height: 100px;
    font-size: 22px;
    color: #f2f2f2;
    line-height: 100px;
    cursor: pointer;

}

.activetag {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
}

.bannerimg {
    width: 100%;
    height: auto;

}

.textcenter {
    text-align: center;
}

.chanpintitle {
    width: 920px;
    height: 180px;
    margin-top: 20px;
}

.chanpinmainbox {
    display: flex;
    // align-items: center;
    justify-content: space-between;
}

.chanpinleftbox {
    width: 50%;
    text-align: center;
}

.chanpinleftitle {
    font-size: 44px;
}

.chanpinleftext {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    background-color: #c29460;
    width: 500px;
    border-radius: 8px;
    color: #f2f2f2;


    &:hover {
        color: #ed8f25;
        font-size: 40px;
    }
}

.chanpinrightbox {
    width: 50%;
    text-align: center;

}

.chanpinrightimg {
    max-width: 288px;

}

.hottitle {
    text-align: center;
    font-weight: bolder;
    font-size: 44px;

    background-color: #ed8f25;
    color: #f2f2f2;
    height: 180px;
    line-height: 180px;
}

.hotmainbox {
    display: flex;
    justify-content: center;
    // margin-top: 20px;
    margin-bottom: 20px;
    background-color: #ed8f25;
    padding-bottom: 30px;
}

.hotmainitem {
    // padding: 30px;
    // width: 30%;
    // color: #f2f2f2;

    width: 344px;
   // height: 466px;
    height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 0px rgba(51, 51, 51, 0.4);
    border-radius: 8px 8px 8px 8px;
    margin: 0 30px;
   
}

.hotmainimg {
    // height: 300px;

    width: 344px;
    height: 194px;
    border-radius: 8px 8px 0px 0px;
}

.hotelname {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;

}

.hoteltext {
    // font-size: 18px;
    // margin-top: 20px;
    text-align: left;
   // line-height: 30px;
    font-size: 14px;
    color: #666666;
    line-height: 16px;
    padding: 20px;
    height: 190px;
    overflow: hidden;

}

.mytitle {
    text-align: center;
    font-weight: bolder;
    font-size: 44px;
    padding-top: 20px;

}

.ustitleimg {
    width: 590px;
    height: 180px;

}

.uscontent {
    padding: 30px;
    width: 80%;
    margin: auto;
    line-height: 45px;
    text-align: left;
   letter-spacing: 4px;
   padding-bottom: 50px;
   color: #666666;
}
.footbox{
    width: 100%;
    height: 333px;
    background-color: black;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footzhanwei{
    width: 100%;
    height: 100px;
   
}
.margin50{
    margin: 50px;
}
</style>