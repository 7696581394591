import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import './assets/css/global.css';


Vue.use(ElementUI);

Vue.config.productionTip = false


  
// 将axios绑定到Vue的原型上，这样你可以在任何组件中通过this.$axios来使用它  
Vue.prototype.$axios = axios;  
  
// 或者，你可以创建一个全局变量  
window.axios = axios;  
  

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
