import request from '../../utils/request'

// 获取新闻列表
export function getnewList() {
    return request({
      url: '/news/getNewsList',
      method: 'get'
    })
  }
  
// 获取新闻详情
export function getnewsDetail(id) {
  return request({
    url: '/news/get?id=' + id,
    method: 'get'
  })
}