import { render, staticRenderFns } from "./AboutViex.vue?vue&type=template&id=cb4cc954&scoped=true"
var script = {}
import style0 from "./AboutViex.vue?vue&type=style&index=0&id=cb4cc954&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb4cc954",
  null
  
)

export default component.exports