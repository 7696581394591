<template>
    <div v-html="newscontent" class="newsdetail">

    </div>
</template>
<script>
import { getnewsDetail } from '@/api/content/news'
export default {
    name: 'news',
    data() {
        return {
            newscontent: ''
        }
    }, methods: {
        getScreen() {
      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      ) {
        this.isPC = true;
        this.pdbsf = 1;
        console.log("手机端");
      } else {
        this.isPC = false;
        this.pdbsf = 0;
        console.log("pc端");
      }
    }
    }, created() {
     this.getScreen()
        console.log(this.$route.query.newsId);
        console.log(111);
        getnewsDetail(this.$route.query.newsId).then(res => {
            console.log(res);
            this.newscontent = res.data.newsContent;

        }).catch(err => {
            console.log(err);
        })
    }
}
</script>
<style lang="less" scoped>
.newsdetail {
    text-align: center;
    padding: 20px;
}

.menhu {
    position: fixed;
    margin: 0 auto;
    left: 39.2%;
    background-color: rgb(0, 0, 0);
    width: 375px;
    height: 44px;
}
</style>