import { render, staticRenderFns } from "./Downloadpage.vue?vue&type=template&id=08663200&scoped=true"
import script from "./Downloadpage.vue?vue&type=script&lang=js"
export * from "./Downloadpage.vue?vue&type=script&lang=js"
import style0 from "./Downloadpage.vue?vue&type=style&index=0&id=08663200&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08663200",
  null
  
)

export default component.exports