import axios from 'axios';
 
// 创建axios实例

const service  = axios.create({
  // baseURL: 'http://192.168.110.2:8256/api', // API的base_url
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // 请求超时时间
});
 
// 请求拦截器
service .interceptors.request.use(
  
  config => {
    // 可以在这里添加请求头等信息
    config.headers['PLATFORM'] = 'pc';
    return config;
  },
  error => {
    // 请求错误处理
    console.log(error);
    return Promise.reject(error);
  }
);
 
// 响应拦截器
service .interceptors.response.use(
  response => {
    const res = response.data;
    // 根据后端返回的数据进行处理，例如返回状态码等
    return res;
  },
  error => {
    // 响应错误处理
    console.log('err' + error); // for debug
    return Promise.reject(error);
  }
);
 
export default service ;