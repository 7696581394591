<template>
  <div class="home">
    <div class="mainbox">
      <img class="mainbg" src="../assets/bg3.png" alt="" @click="toAbout">
      <div class="titlefont">
        临时用工就上新工宝
      </div>
      <div class="logobox">
        <img class="logoimg" src="../assets/logo2.png" alt="">
      </div>
      <div class="logoname">
        <img class="logonameimg" src="../assets/xgb.png" alt="">
      </div>
      <div class="lablebox">
        零工信息共享平台
      </div>
      <div class="downloadbtnbox" id="downloadButton">
        立即下载
      </div>
    </div>



  </div>
</template>

<script type="text/javascript" charset="UTF-8" src="https://web.cdn.openinstall.io/openinstall.js"></script>
<script type="text/javascript">
  	var data = OpenInstall.parseUrlParams();///openinstall.js中提供的api，解析当前网页url中的查询参数并对data进行赋值
	new OpenInstall({//初始化方法，与openinstall服务器交互，应尽早调用
		appKey : "c7nn2a",//appKey为openinstall为应用分配的唯一id（必须传入）
		onready : function() {//初始化成功回调方法。当初始化完成后，会自动进入
			this.schemeWakeup();//尝试使用scheme打开App（主要用于Android以及iOS的QQ环境中）
			var m = this, button = document.getElementById("downloadButton");//为button对象绑定对应id的元素
			button.onclick = function() {//对应button的点击事件
       
				m.wakeupOrInstall();//此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
				return false;
			}
		}
	}, data);//初始化时传入data，作为一键拉起/App传参安装时候的参数
</script>
  
<script>
// @ is an alias to /src


export default {
  name: 'Downloadpage',
  components: {

  },
  mounted:function(){
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = '//res.cdn.openinstall.io/openinstall.js';
            s.addEventListener('load',()=>{
              var data = OpenInstall.parseUrlParams();//openinstall.js中提供的工具函数，解析url中的所有查询参数
              new OpenInstall({
                  /*appKey必选参数，openinstall平台为每个应用分配的ID*/
                  appKey : "c7nn2a",
                  /*可选参数，自定义android平台的apk下载文件名；个别andriod浏览器下载时，中文文件名显示乱码，请慎用中文文件名！*/
                  //apkFileName : 'com.fm.openinstalldemo-v2.2.0.apk',
                  /*可选参数，是否优先考虑拉起app，以牺牲下载体验为代价*/
                  //preferWakeup:true,
                  /*自定义遮罩的html*/
                  //mask:function(){
                  //  return "<div id='openinstall_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
                  //},
                  /*openinstall初始化完成的回调函数，可选*/
                  onready : function() {
                      var m = this, button = document.getElementById("downloadButton");
                      button.style.visibility = "visible";
 
                      /*在app已安装的情况尝试拉起app*/
                      m.schemeWakeup();
                      /*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
                      button.onclick = function() {
                        console.log('点击了下载按钮');
                          m.wakeupOrInstall();
                          return false;
                      }
                  }
              }, data);
            },false)
            document.head.appendChild(s);
       },
      
  methods: {
    toAbout() {
      console.log('toAbouu')
      this.$router.push('/abouu')
    }
  }
}
</script>
<style scoped>
.home {
  text-align: center;

}

.mainbox {
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.mainbg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  max-width: 1200px;
  height: auto;
  object-fit: contain;
}

.titlefont {
  padding-top: 45px;
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}

.logobox {
  text-align: center;
  margin-top: 75px;

}

.logoimg {
  width: 80px;
  height: auto;
  object-fit: contain;


}

.logoname {
  margin-top: 5px;
  text-align: center;


}

.logonameimg {
  width: 96px;
  height: auto;
  object-fit: contain;
}

.lablebox {
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
  color: #333333;
}

.downloadbtnbox {
  margin: 0 auto;
  text-align: center;
  margin-top: 114px;
  width: 327px;
  height: 50px;
  background: linear-gradient(59deg, #04DECC 0%, #2BA5B4 100%);
  box-shadow: 0px 8px 14px -4px rgba(43, 165, 180, 0.4);
  border-radius: 8px 8px 8px 8px;
  line-height: 50px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;

}
</style>
  